.editorContainerOuter {
    margin: 0 .5rem;
    flex: 2;
    display: flex;
    flex-direction: column;
}

.loaderContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    background: rgba(0, 0, 0, 0.85);
    z-index: 100;
    text-align: center;
    font-size: 1.2rem;
}


.DraftEditor-root {
    overflow: auto;
    flex: 1;
    padding: .5rem;
    margin: .5rem;
}

.public-DraftEditorPlaceholder-root {
    width: initial;
}

.public-DraftEditor-content {
    overflow-wrap: anywhere !important;
}


div > .public-DraftStyleDefault-block {
    margin-bottom: .5rem;
}

div > .public-DraftStyleDefault-block,
li > .public-DraftStyleDefault-block {
    line-height: 1.6rem;
    /*text-align: justify;*/
}


.MuiTooltip-tooltip {
    font-size: 1rem !important;
}


/*
shell-logo-colours {
    lightGreen: #CCDA58;
    darkGreen: #A0C855;
    lightBlue: #3A79B9;
    darkBlue: #2E60A8;
    bluePurple: #312C73;
    purple: #5A2872;
    pink: #DB3E73
}
*/

/*
Readability

    Grade: X

    Long words  *
    Long sentences  *

Structure
    Long paragraph(s)  *
    Complex sentences  *

Complex Language
    Thesaurus  ****
    Uncommon words **
    Legal terms  *  // Add colours to list above
    Financial terms  *  // Add colours to list above
    Acronyms  **

Passive Voice
    Passive voice  ***

Lexical Density
    Score: XX

Person-centred Language
    Diabetes / Dementia / Chronic Pain / Cancer  ****

*/

.highlight-span {
    /*padding: 1px 2px;*/
    /*border-radius: 4px;*/
}


.highlight-span.hovered, .decorator.hovered {
    /*border-radius: 4px;*/
}

.decorator.hovered {
    margin: -1px;
    z-index: 5;
    border: 1px solid currentColor;
}

.highlight-yellow {
    background: #faeecb;
    color: #3b2c05;
}

.highlight-yellow.hovered, .highlight-yellow.tooltip-container {
    background: #fbe78d
}

.highlight-darkPurple {
    background: #eae7f8;
    color: #251e4c;
}

.highlight-darkPurple.hovered, .highlight-darkPurple.tooltip-container {
    background: #c6c0e1
}


.highlight-pinkRed {
    background: #eaa2a8;
    color: #410318;
}

.highlight-pinkRed.hovered, .highlight-pinkRed.tooltip-container {
    background: #ef717e;
}

/* If a thesaurus decorator marks a word that was excluded, we still highlight it but display it differently */
.highlight-pinkRed.excluded, .highlight-pinkRed.tooltip-container.excluded {
    background: white;
    border: 1px dashed #eaa2a8;
}

.highlight-pinkRed.excluded.hovered {
    border-width: 2px;
    padding: 0 1px;
}


.highlight-lightPink {
    background: #efc0d5;
    color: #4f122f;
}

.highlight-lightPink.hovered, .highlight-lightPink.tooltip-container {
    background: #f3b4d9;
}


.highlight-purple {
    background: #c6aed5;
    color: #2b0f3c;
}

.highlight-purple.hovered, .highlight-purple.tooltip-container {
    background: #b29dc0
}


.highlight-lightPurple {
    background: #dcc5e8;
    color: #30114f;
}

.highlight-lightPurple.hovered, .highlight-lightPurple.tooltip-container {
    background: #c6b1d1
}


.highlight-orange {
    background: #f6b291;
    color: #4c1b07;
}

.highlight-orange.hovered, .highlight-orange.tooltip-container {
    background: #f8964b
}


.highlight-lightBlue {
    background: #a7d5e3;
    color: #1b2f34;
}

.highlight-lightBlue.hovered, .highlight-lightBlue.tooltip-container {
    background: #71d2e4
}


.highlight-blueGreen {
    background: #caefe0;
    color: #153c2b;
}

.highlight-blueGreen.hovered, .highlight-blueGreen.tooltip-container {
    background: #9ee8cf
}

.highlight-darkGreen {
    background: #d5f5c7;
    color: #072803;
}

.highlight-darkGreen.hovered, .highlight-darkGreen.tooltip-container {
    background: #b8f094
}


.readability-sentence {
    background: initial;
    color: initial;
    cursor: pointer;
}

.readability-sentence:hover {
    background: #e5e5e5;
}

.readability-sentence:active {
    outline: 1px solid grey;
}


.highlight-ignore {
    color: grey;
    font-style: italic;
    background: lightgrey;
}


@media print {
    .force-print-background {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
}
