.header {
    background: #322c76;
    padding: 2rem 1rem;
    text-align: center;
}

.header.pinned {
    background: #ed2e71;
}

.header > h1 {
    color: white;
}

.body {
    padding: 1rem;
}

.stats thead {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.stats {
    border-bottom: 1px solid black;
    margin-bottom: .4rem;
}

.stats td, th {
    padding: .2rem .7rem;
}

.stats tr:last-child td {
    padding-bottom: .7rem;
}

.stats td:first-child {
    width: 40%;
}

.stats td:not(:first-child) {
    width: 20%;
}

.stats tr.spacer {
    height: 1rem;
}

.category {
    background: #e0dff3;
}

.fit-content {
    width: 1px;
    white-space: nowrap;
}


/*PAGE BREAK STUFF*/
@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin: 0 !important;
        display: block;
        page-break-before: always;
    }
}

@page {
    size: auto;
    margin: 2rem;
}
