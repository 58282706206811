.tooltip-alternatives {
    border: 1px solid grey;
    margin: .2rem 0;
    padding: .3rem;
}

.tooltip-container {
    padding: .5rem;
    border-radius: 4px;
    border: 1px solid currentColor;
}

.tooltip-container a.red-link {
    color: #bc1212 !important;
    text-decoration: underline;
}

.tippy-content {
    padding: 0;
    font-size: .9rem;
}
