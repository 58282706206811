.draftJsToolbar__toolbar__dNtBH {
    background: #fbfbfb;
    white-space: pre;
    overflow-x: auto;
    text-align: center;
}

.toolbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.toolbar-buttons {
    display: flex;
}

.toolbar-buttons-box {
    margin-right: .5rem;
    flex: 1;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
}

.toolbar-buttons-box:first-child > * {
    margin-right: auto !important;
}

.toolbar-buttons-box:last-child > * {
    margin-left: auto !important;
}

.toolbar > div {
    box-shadow: none;
}

.tour-icon {
    padding: .3rem !important;
}

.refresh-button {
    margin-left: .8rem !important;
}

.refresh-button.glowing:not(:disabled) {
    -webkit-animation: glowing 1s infinite;
    -moz-animation: glowing 1s infinite;
    animation: glowing 1s infinite;
}

@keyframes glowing {
    0% {
        background-color: #dc3545;
        box-shadow: 0 0 3px #dc3545;
    }
    50% {
        background-color: #ff001f;
        box-shadow: 0 0 12px #ff001f;
    }
    100% {
        background-color: #dc3545;
        box-shadow: 0 0 3px #dc3545;
    }
}

.draftJsToolbar__buttonWrapper__1Dmqh {
    vertical-align: unset;
}

.toolbar_head_one,
.toolbar_head_two,
.toolbar_head_three {
    vertical-align: unset;
    height: 100%;
    width: 100%;
}

.toolbar_head_one {
    font-size: 28px;
}

.toolbar_head_two {
    font-size: 24px;
}

.toolbar_head_three {
    font-size: 20px;
}
