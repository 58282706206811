.counterContainer {
    border: 1px solid #ddd;
    border-top: 0;
    display: flex;
    justify-content: center;
    background: #fbfbfb;
}

.counterContainer > span {
    padding: 0 .4rem;
    text-align: center;
}

.counterContainer > span:not(:last-child) {
    border-right: 1px solid lightgrey;
}


