.container {
    display: flex;
    flex-direction: column;
}

.categoryToggle, .decoratorToggle {
    margin: 0 !important;
}

.decoratorToggle {
    padding-left: 1.5rem;
}

.decorator-list-checkbox {
    padding: 0 .5rem 0 0 !important;
}

.toggle-container {
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
    padding-top: 8px;
}

#decorator-menu ul {
    padding-top: 0;
    min-width: 250px;
}
