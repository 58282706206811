.collapse-title-container:hover:after {
    content: '';
    position: absolute;
    inset: -5px -3px -5px -3px;
    background: #efefef;
    border-radius: 4px;
    z-index: -1;
}

.collapse-title-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
}

.collapse-title,
.collapse-title-expand {
    display: flex;
    align-items: center;
}

