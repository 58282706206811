.sidebar {
    flex: 1;
    min-width: 125px;
    text-align: center;
    overflow-y: auto;
    padding: 0 .3rem;
    margin-right: .3rem;
}

.top-sidebar {
    background: white;
    /*padding-top: .6rem;*/
    position: sticky;
    top: 0;
    z-index: 20
}

.decorator {
    padding: .35rem .75rem !important;
}

.decorator.hovered {
    position: relative;
    margin: -2px;
}

.decorator:nth-child(2) {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.decorator:last-child {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.decorator a {
    color: inherit;
    text-decoration: underline;
}


.decorators {
    text-align: left;
}

.decorators small {
    color: grey;
}

.decorators-section {
    scroll-margin: 8rem;
    padding: 1rem 0;
}

.button-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

