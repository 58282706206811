body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* Scrollbar for Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #c7c7c7 #f6f6f6;
}

/* Scrollbar for Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
    background-color: #f6f6f6;
    border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c7c7c7;
}


button:focus {
    outline: none !important;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.flex-center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reactour__helper > [data-tour-elem='controls'] {
    justify-content: center;
}

.nowrap {
    white-space: pre;
}


